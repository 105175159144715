import {HydratedEvent} from './hydrated-event';
import {SafeResourceUrl} from '@angular/platform-browser';
import {TeamFormObject} from './team-form-object';
import {BannerAdvertisementFormObject} from './banner-ad-form-object';
import * as moment from 'moment-timezone';
import {DateUtils} from '../../utils/date-utils';

export class EventFormObject {
  public event: HydratedEvent;
  public imageToUpload: string | SafeResourceUrl;
  public existingImageId: string;
  public deleteImageId: string;
  public teamFormObjects: TeamFormObject[];
  public bannerAdFormObjects: BannerAdvertisementFormObject[];
  public eventDate: string = null;

  static initWithEvent(event: HydratedEvent): EventFormObject {
    const formObject = new EventFormObject();
    formObject.event = event;
    if (event.eventStartDate) {
    formObject.eventDate = DateUtils.formatDateInputString(event.eventStartDate);
    }
    if (event.eventLogos?.length > 0) {
      formObject.existingImageId = event.eventLogos[0]?.id;
    }
    formObject.teamFormObjects = event.associatedTeams
      ?.map(t => TeamFormObject.initWithTeam(t)) ?? [];
    formObject.bannerAdFormObjects = event.advertisementBanners
      ?.map(b => BannerAdvertisementFormObject.initWithBannerAdvertisement(b)) ?? [];
    return formObject;
  }
}
